import { Routes } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth/auth.guard';

export const missionTabsRoutes: Routes = [
  {
    path: 'missions',
    loadComponent: () => import('src/app/pages/missions/missions/missions.page').then((m) => m.MissionsPage),
    canActivate: [AuthGuard]
  }
];
