import { Routes } from '@angular/router';
import { AuthGuard } from '../../guards/auth/auth.guard';
import { StructureInfoGuard } from '../../guards/structure-info/structure-info.guard';
import { DesktopGuard } from '../../guards/desktop-guard/desktop.guard';

export const structureRoutes: Routes = [
  {
    path: 'structure',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'add-structure',
        loadComponent: () => import('./add-structure/add-structure.page').then((m) => m.AddStructurePage)
      },
      {
        path: ':structureId',
        canActivate: [StructureInfoGuard],
        children: [
          {
            path: '',
            loadComponent: () => import('./structure/structure.page').then((m) => m.StructurePage)
          },
          {
            path: 'edit-infos',
            loadComponent: () => import('./edit-information/edit-information.component').then((m) => m.EditInformationComponent)
          },
          {
            path: 'add-missing-informations',
            loadComponent: () => import('./add-missing-informations/add-missing-informations.page').then((m) => m.AddMissingInformationsPage)
          },
          {
            path: 'edit-pictures',
            loadComponent: () => import('./edit-pictures/edit-pictures.page').then((m) => m.EditPicturesPage)
          },
          {
            path: 'edit-colleagues',
            loadComponent: () => import('./edit-colleagues/edit-colleagues.page').then((m) => m.EditColleaguesPage)
          },
          { path: 'user-access', loadComponent: () => import('../user-access/user-access.page').then((m) => m.UserAccessPage) }
        ]
      },
      {
        path: '**',
        redirectTo: '',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'structures',
    canActivate: [AuthGuard, DesktopGuard],
    loadComponent: () => import('./structures/structures.page').then((m) => m.StructuresPage)
  }
];
